import { Region } from '@watershed/config/types';
import RadioField from '@watershed/ui-core/components/Form/RadioField';
import { REGIONS_ORDERED } from '../utils/dataResidency';
import { Trans } from '@lingui/react/macro';

export default function CreateOrganizationDataResidencyRegionField({
  id,
  name,
  region,
}: { region: Region } & Pick<
  React.ComponentProps<typeof RadioField>,
  'id' | 'name'
>) {
  return (
    <RadioField
      id={id}
      row
      name={name}
      label={<Trans context="form label">Data residency region</Trans>}
      sublabel={
        <Trans>
          To create an org in a different region, visit Admin in that region
          (e.g., eu.admin.watershedclimate.com).
        </Trans>
      }
      required
      options={Object.values(REGIONS_ORDERED).map((value) => ({
        label: value.toUpperCase(),
        value,
        disabled: value !== region,
      }))}
    ></RadioField>
  );
}
