import { Box, MenuItem, Stack } from '@mui/material';
import AddIcon from '@watershed/icons/components/Add';
import { useAllOrganizationsQuery } from '@watershed/shared-frontend/generated/urql';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import Button from '@watershed/ui-core/components/Button';
import gql from 'graphql-tag';
import dynamic from 'next/dynamic';
import { useRef, useState } from 'react';
import {
  adminLoginAsMyselfUrl,
  adminLoginAsUrl,
  urlForObject,
} from '@watershed/shared-universal/adminRoutes';
import { getGqlResultData } from '@watershed/shared-frontend/utils/errorUtils';
import { LoginAsButtons } from '../LoginAsButtons';
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import {
  GQOrganizationListPageOrganizationFragment,
  GQWatershedPlanLegacy,
} from '@watershed/shared-universal/generated/graphql';
import CopyIcon from '@watershed/icons/components/Copy';
import copyToClipboard from 'copy-to-clipboard';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import MoreActionsButton from '@watershed/ui-core/components/MoreActionsButton';
import useKeydown from '@watershed/shared-frontend/hooks/useKeydown';
import {
  FavoriteOrgButton,
  useFavoriteOrgs,
} from '../../utils/referenceOrgUtils';
import CreateDemoOrgDialog from '@watershed/shared-frontend/components/entFound/CreateDemoOrgDialog';
import { useAdminContext } from '../AdminContext';
import { ObjectList } from '@watershed/ui-core/components/ObjectList/ObjectList';
import {
  navigateToPathOnCallback,
  navigateToPathOnClick,
} from '@watershed/ui-core/utils/NavigationUtils';
import { useRouter } from 'next/router';
import { getOverflowMenuColumn } from '@watershed/ui-core/components/ObjectList/ObjectListOverflowMenu';
import { Pill } from '@watershed/ui-core/components/Pill';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';
import { previewDeployNames } from '@watershed/shared-universal/utils/helpers';
import OverflowChipList from '@watershed/shared-frontend/components/OverflowChipList';
import ChevronUpIcon from '@watershed/icons/components/ChevronUp';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';

gql`
  fragment OrganizationListPageOrganization on Organization {
    id
    name
    domains
    demoOrg
    testOrg
    stagingOrg
    canAccessFinance
    hasUsers
    watershedPlanLegacy
  }

  query AllOrganizations {
    organizations {
      edges {
        node {
          __typename
          ...OrganizationListPageOrganization
        }
      }
    }
  }
`;

const AddOrganizationDialog = dynamic({
  loader: () => import('./AddOrganizationDialog'),
});

type DialogKind = 'createOrg' | 'createDemoOrg';

function DialogController({
  kind,
  ...props
}: {
  kind: DialogKind | null;
  onClose: () => void;
  onSubmitComplete: () => void;
}) {
  const { activeWatershedEmployee } = useAdminContext();
  switch (kind) {
    case 'createOrg':
      return <AddOrganizationDialog {...props} />;
    case 'createDemoOrg':
      return (
        <CreateDemoOrgDialog
          {...props}
          userId={activeWatershedEmployee.user.id}
        />
      );
    case null:
      return null;
  }
}

export default function OrgList({
  referenceOrgsOpen,
  toggleReferenceOrgs,
}: { referenceOrgsOpen: boolean; toggleReferenceOrgs: () => void }) {
  const router = useRouter();
  const [dialogKind, setDialogKind] = useState<DialogKind | null>(null);
  const [showAllOrgsParam, setShowAllOrgs] = useQueryParam('showAllOrgs');
  const showAllOrgs = showAllOrgsParam === 'yes';

  const gridColumns = useOrgListColumns();

  const [result, executeRefresh] = useAllOrganizationsQuery();
  const { favoriteOrgIds } = useFavoriteOrgs();
  const favoriteOrgIdsSet = new Set(favoriteOrgIds);

  const data = getGqlResultData(result);
  const orgs = flattenConnection(data?.organizations)
    .filter((org) =>
      showAllOrgs || favoriteOrgIdsSet.has(org.id) ? true : org.demoOrg
    )
    .sort(
      (a, b) => +favoriteOrgIdsSet.has(b.id) - +favoriteOrgIdsSet.has(a.id)
    );

  const loading = isFetchingOrStale(result);

  return (
    <>
      <Box
        sx={{
          // this is necessary because of datagrid weirdness, it'll keep
          // expanding if you don't set these
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <ObjectList<GQOrganizationListPageOrganizationFragment>
          rows={orgs}
          dense
          // this makes it so that when the collapsible closes, the grid doesn't
          // have a bunch of empty space cuz rows are still in the DOM
          rowBuffer={10}
          loading={loading}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showAllOrgs,
              setShowAllOrgs,
              setDialogKind,
              referenceOrgsOpen,
              toggleReferenceOrgs,
            },
          }}
          onRowClick={(row, event) =>
            navigateToPathOnClick(
              event,
              router,
              urlForObject('Organization', row.id.toString())
            )
          }
          sx={{
            '& .MuiDataGrid-cell:hover': {
              cursor: 'pointer',
            },
          }}
          getRowId={(row) => row.id.toString()}
          columns={gridColumns}
        />
      </Box>
      <DialogController
        kind={dialogKind}
        onClose={() => {
          setDialogKind(null);
        }}
        onSubmitComplete={() => {
          executeRefresh({ requestPolicy: 'cache-and-network' });
          setDialogKind(null);
        }}
      />
    </>
  );
}

function GridToolbar({
  showAllOrgs,
  setShowAllOrgs,
  setDialogKind,
  referenceOrgsOpen,
  toggleReferenceOrgs,
}: {
  showAllOrgs: boolean;
  setShowAllOrgs: (arg: string | null) => void;
  setDialogKind: (arg: DialogKind) => void;
  referenceOrgsOpen: boolean;
  toggleReferenceOrgs: () => void;
}) {
  const filterRef = useRef<HTMLInputElement | null>(null);

  useKeydown((e) => {
    // Focus the filter input on cmd+f or ctrl+f
    if ((e.metaKey || e.ctrlKey) && e.key === 'f' && filterRef.current) {
      e.preventDefault();
      filterRef.current.focus();
    }
  });

  return (
    <GridToolbarContainer
      sx={{
        gap: 1,
        marginBottom: -1,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        startIcon={referenceOrgsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={toggleReferenceOrgs}
      >
        Reference orgs
      </Button>
      <Stack direction="row" sx={{ gap: 1 }}>
        <GridToolbarQuickFilter
          autoFocus
          inputProps={{ ref: filterRef }}
          fsUnmask
          sx={{ minWidth: 360 }}
        />
        <Button
          onClick={() =>
            showAllOrgs ? setShowAllOrgs(null) : setShowAllOrgs('yes')
          }
          tooltip="Tip: use Cmd+K to quickly jump between orgs"
        >
          {showAllOrgs ? 'Hide non-demo orgs' : 'Show all orgs'}
        </Button>
        <MoreActionsButton
          tooltip="Create a new organization"
          icon={<AddIcon />}
        >
          <MenuItem onClick={() => setDialogKind('createOrg')}>
            Create organization
          </MenuItem>
          <MenuItem onClick={() => setDialogKind('createDemoOrg')}>
            Create demo organization
          </MenuItem>
        </MoreActionsButton>
      </Stack>
    </GridToolbarContainer>
  );
}

const FavoriteCell: React.FC<{ orgId: string }> = (props) => {
  return (
    <Box padding={0.5}>
      <FavoriteOrgButton orgId={props.orgId} />
    </Box>
  );
};

function useOrgListColumns(): Array<
  GridColDef<GQOrganizationListPageOrganizationFragment>
> {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { activeWatershedEmployee } = useAdminContext();
  const accessibleOrgIds = activeWatershedEmployee.user.accessibleOrgs.map(
    (org) => org.id
  );
  const shouldRenderPreviewDeployOptions = getCurrentDevEnv() !== 'local-dev';

  return [
    {
      field: 'favorite',
      headerName: '',
      renderCell: ({ row }) => {
        return <FavoriteCell orgId={row.id} />;
      },
      sortable: false,
      disableColumnMenu: true,
      width: 32,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      maxWidth: 280,
      renderCell: ({ row }) => <ObjectList.TitleCell title={row.name} />,
    },
    {
      field: 'kind',
      headerName: 'Kind',
      flex: 0.5,
      minWidth: 160,
      maxWidth: 320,
      renderCell: ({ row }) => <OrgKindPillList {...row} />,
    },
    {
      field: 'loginAs',
      headerName: 'Login as',
      minWidth: 196,
      renderCell: ({ row }) => (
        <LoginAsButtons
          targetOrgId={row.id}
          disabled={!row.hasUsers}
          // we can hide these because we're showing them in the overflow menu
          hidePreviewDeployOptions
          size="large"
        >
          Login
        </LoginAsButtons>
      ),
    },
    {
      field: 'domains',
      headerName: 'Domains',
      flex: 1,
      renderCell: ({ row }) => (
        <OverflowChipList
          items={row.domains.map((domain) => ({ label: domain }))}
          // no need to render the popover if there's only one
          alwaysRenderOverflow={false}
          itemDisplay={{
            type: 'popover',
            renderPopover: () => (
              <Box
                sx={{
                  padding: 2,
                  width: 320,
                }}
              >
                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                  {row.domains.map((domain) => (
                    <Pill key={domain} label={domain} />
                  ))}
                </Stack>
              </Box>
            ),
          }}
        />
      ),
    },
    getOverflowMenuColumn(({ row }) => {
      const hasAccessToOrg = accessibleOrgIds.includes(row.id);
      return {
        dropdownItems: [
          ...(shouldRenderPreviewDeployOptions
            ? previewDeployNames.map((name) => ({
                id: name,
                label: `Login to ${name}`,
                onSelect: () =>
                  hasAccessToOrg
                    ? navigateToPathOnCallback(
                        router,
                        adminLoginAsMyselfUrl(row.id, {
                          previewDeployName: name,
                        })
                      )
                    : navigateToPathOnCallback(
                        router,
                        adminLoginAsUrl({
                          orgId: row.id,
                          previewDeployName: name,
                        })
                      ),
              }))
            : []),
        ],
        actions: [
          {
            id: 'copy',
            tooltip: 'Copy org ID to clipboard',
            IconElement: CopyIcon,
            onClick: () => {
              copyToClipboard(row.id);
              enqueueSnackbar(`Copied Org ID to clipboard`, {
                variant: 'success',
              });
            },
          },
        ],
      };
    }),
  ];
}

function OrgKindPillList(props: {
  name: string;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  canAccessFinance: boolean | null;
  watershedPlanLegacy?: GQWatershedPlanLegacy;
}) {
  // let's not get rid of the pointer when you hover
  const sx = {
    cursor: 'pointer',
  };
  const pills: Array<React.ReactNode> = [];
  if (!props.testOrg && !props.demoOrg) {
    pills.push(<Pill key="Customer" label="Customer" color="error" sx={sx} />);
  }
  if (props.testOrg) {
    pills.push(<Pill key="Test" label="Test" sx={sx} />);
  }
  if (props.demoOrg) {
    pills.push(<Pill key="Demo" label="Demo" color="warning" sx={sx} />);
  }
  if (props.watershedPlanLegacy === GQWatershedPlanLegacy.NoPlan) {
    pills.push(
      <Pill key="Supplier" label="Supplier" color="decorative" sx={sx} />
    );
  }
  if (props.stagingOrg) {
    pills.push(<Pill key="Staging" label="Staging" color="primary" sx={sx} />);
  }
  if (props.canAccessFinance) {
    pills.push(<Pill key="Finance" label="Finance" color="success" sx={sx} />);
  }
  return (
    <Stack direction="row" gap={1}>
      {pills}
    </Stack>
  );
}
